import React, { Component } from 'react';
import {Faq_item1} from '../Faq/Faq_item1';
import {Faq_item2} from '../Faq/Faq_item2';
import {Faq_item3} from '../Faq/Faq_item3';
import {Faq_item4} from '../Faq/Faq_item4';
import {Faq_item5} from '../Faq/Faq_item5';
import {Faq_item6} from '../Faq/Faq_item6';
import {Faq_item7} from '../Faq/Faq_item7';

export class Faq extends Component {

    render() {
        return(
            <div className="c-faq-list">
                <Faq_item1 />
                <Faq_item2 />
                <Faq_item3 />
                <Faq_item4 />
                <Faq_item5 />
                <Faq_item6 />
                <Faq_item7 />
            </div>
        )
    }
}
