import React, { Component } from 'react';
import phonesImg from '../../img/phone-UI.png';
import phonesImg2x from '../../img/phone-UI@2x.png';
import iconSim from '../../img/icon-sim.svg';
import iconHome from '../../img/icon-home.svg';
import iconWifi from '../../img/icon-wifi.svg';
import data from "../../data";

export class SectionTutorial extends Component {

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {

        return(
            <section className="c-section" id="section-tutorial">
                <div className="c-layout">
                    <div className="c-section__subtitle" data-aos="fade-up" data-aos-duration="300" data-aos-delay="100">
                        {this.content.section_tutorial.title}
                    </div>
                    <div className="c-section__container">
                        <div className="c-section__meta">
                            <div className="c-tutorials-list">
                                <article className="c-tutorial-item">
                                    <div className="c-tutorial-item__meta">
                                        <strong data-aos="zoom-out" data-aos-duration="300" data-aos-delay="200">01</strong>
                                        <p data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
                                            {this.content.section_tutorial.step1}
                                        </p>
                                    </div>
                                    <div className="c-tutorial-item__media" data-aos="fade-in" data-aos-duration="300" data-aos-delay="200">
                                        <img src={iconSim} alt=""/>
                                    </div>
                                </article>
                                <article className="c-tutorial-item">
                                    <div className="c-tutorial-item__media" data-aos="fade-in" data-aos-duration="300" data-aos-delay="400">
                                        <img src={iconHome} alt=""/>
                                    </div>
                                    <div className="c-tutorial-item__meta">
                                        <strong data-aos="zoom-out" data-aos-duration="300" data-aos-delay="400">02</strong>
                                        <p data-aos="fade-up" data-aos-duration="300" data-aos-delay="500">
                                            {this.content.section_tutorial.step2}
                                        </p>
                                    </div>
                                </article>
                                <article className="c-tutorial-item">
                                    <div className="c-tutorial-item__meta">
                                        <strong data-aos="zoom-out" data-aos-duration="300" data-aos-delay="600">03</strong>
                                        <p data-aos="fade-up" data-aos-duration="300" data-aos-delay="600">
                                            {this.content.section_tutorial.step3}
                                        </p>
                                    </div>
                                    <div className="c-tutorial-item__media" data-aos="fade-in" data-aos-duration="300" data-aos-delay="600">
                                        <img src={iconWifi} alt=""/>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="c-section__media c-section__media--big" data-aos="fade-up" data-aos-duration="300" data-aos-delay="200">
                            <figure className="c-figure--phone-ui">
                                <img src={phonesImg} srcSet={`${phonesImg2x} 2x`} alt=""/>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
