import React, { Component } from 'react';

export class Faq_item7 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        7. Список доступных услуг
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        7. Жеткиликтүү кызматтардын тизмеси
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <ul>
                            <li>Переадресация,</li>
                            <li>Звонок за счет друга,</li>
                            <li>Пауза</li>
                            <li>Твой номер,</li>
                            <li>«Запрет информационной SMS рассылки»,</li>
                            <li>«Международный доступ»,</li>
                            <li>«Конференц-связь»,</li>
                            <li>«Мобильный интернет» (*181*1#),</li>
                            <li>4G (*460),</li>
                            <li>Ожидание и удержание вызова» (*43#),</li>
                            <li>«Определитель номера»,</li>
                            <li>«Переадресация вызова» (**21*номер телефона#),</li>
                            <li>«Роуминг»,</li>
                            <li>«Проверка статуса КАП» (*555#),</li>
                            <li>«Активация КАП (карт оплаты)» (*555#),</li>
                            <li>«Бесплатные сервисные службы», «Выбор языка» (*404#),</li>
                            <li>«Проверка номера и тарифа» (*112#),</li>
                            <li>«MegaUSSD» (*515#),</li>
                            <li>«Бесплатный доступ к сайту MEGA»,</li>
                            <li>«Проверка текущих скидок» (*505#),</li>
                            <li>«Проверка баланса» (*500#),</li>
                            <li>Оповещение о низком балансе,</li>
                            <li>Раздача Wi-Fi,</li>
                            <li>MegaPay,</li>
                            <li>Забытый телефон, Проверка текущих скидок» (*505#),</li>
                            <li>Роуминг (постоплатный, предоплатный, GPRS),</li>
                            <li>Проверка баланса» (*500#),</li>
                            <li>«Запрет коротких номеров SMS»,</li>
                            <li>«Запрет коротких номеров IVR», Оповещение о низком балансе</li>
                            <li>Где телефон?</li>
                            <li>Приложение MEGA24</li>
                            <li>Отправка детализации на почту</li>
                            <li>Детализация</li>
                            <li>Прозрачный счет</li>
                            <li>Видеовызовы</li>
                            <li>«Родительский контроль»</li>
                            <li>«Оповещение о низком балансе»</li>
                            <li>Пакет «День-ночь» (только дарение).</li>
                        </ul>
                        <p>Все остальные услуги являются недоступными на ТП «Умные устройства».</p>
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        <ul>
                            <li>«Кайра даректөө»,</li>
                            <li> «Досумдун эсебинен чалуу»,</li>
                            <li>«Тыным»</li>
                            <li>«Сенин номериң»,</li>
                            <li>«Маалыматтык SMS-жөнөтмөлөргө тыюу салуу»,</li>
                            <li>«Эл аралык байланыш»,</li>
                            <li>«Конференц-байланыш»,</li>
                            <li>«Мобилдик интернет» (*181*1#),</li>
                            <li>4G (*460),</li>
                            <li>Чалууну күтүү жана кармап туруу (*43#),</li>
                            <li>«Номерди аныктагыч»,</li>
                            <li>«Чалууну кайра даректөө» (**21* телефон номери#),</li>
                            <li>«Роуминг»,</li>
                            <li>«Алдын ала төлөм картасынын балансын текшерүү» (*555#),</li>
                            <li>«АТКны активдештирүү (төлөм карталарын)» (*555#),</li>
                            <li>«Акысыз тейлөө кызмматтары», «Тилди тандоо» (*404#),</li>
                            <li>«Номерди жана тарифти текшерүү» (*112#),</li>
                            <li>«MegaUSSD» (*515#),</li>
                            <li>«MEGA сайтына акысыз кирүү мүмкүнчүлүгү»,</li>
                            <li>«Учурдагы арзандатууларды текшерүү» (*505#),</li>
                            <li>«Балансты текшерүү» (*500#),</li>
                            <li>«Жетишсиз баланс тууралуу маалымдоо»</li>
                            <li>Wi-Fi таратуу,</li>
                            <li>MegaPay,</li>
                            <li>Унутулган телефон, Учурдагы арзандатууларды текшерүү (*505#),</li>
                            <li>Роуминг (кийин төлөнүүчү, алдын ала төлөнүүчү, GPRS),</li>
                            <li>Балансты текшерүү (*500#),</li>
                            <li>«Кыска SMS -номерлерге тыюу салуу»,</li>
                            <li>«Кыска IVR-номерлерге тыюу салуу»,</li>
                            <li>Телефон кайда?</li>
                            <li>MEGA24 тиркемеси</li>
                            <li>Деталдоону почтага жөнөтүү</li>
                            <li>Деталдоо</li>
                            <li>Ачык эсеп</li>
                            <li>Видеочалуулар</li>
                            <li>«Ата-эне көзөмөлү»</li>
                            <li>«Жетишсиз баланс тууралуу маалымдоо»</li>
                            <li>«Күн-Түн» топтому (белекке берүү гана).</li>
                        </ul>
                        <p>Калган бардык кызматтар «Акылдуу түзмөктөр» ТПда жеткиликтүү болбойт.</p>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
