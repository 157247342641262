import './App.css';
import './css/reset.min.css'
import './css/fonts.css'
import './css/karma.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

import iconFavicon16 from "./img/icons/favicon-16x16.png";
import iconFavicon96 from "./img/icons/favicon-96x96.png";
import iconFavicon194 from "./img/icons/favicon-194x194.png";
import iconFavicon32 from "./img/icons/favicon-32x32.png";
import iconFavicon57 from "./img/icons/apple-touch-icon-57x57.png";
import iconFavicon60 from "./img/icons/apple-touch-icon-60x60.png";
import iconFavicon72 from "./img/icons/apple-touch-icon-72x72.png";
import iconFavicon76 from "./img/icons/apple-touch-icon-76x76.png";
import iconFavicon114 from "./img/icons/apple-touch-icon-114x114.png";
import iconFavicon120 from "./img/icons/apple-touch-icon-120x120.png";
import iconFavicon144 from "./img/icons/apple-touch-icon-144x144.png";
import iconFavicon152 from "./img/icons/apple-touch-icon-152x152.png";
import iconFavicon180 from "./img/icons/apple-touch-icon-180x180.png";
import iconFavicon192 from "./img/icons/android-chrome-192x192.png";

import React, { useEffect, useState } from "react";
import {Header} from './Components/Layout/Header';
import {Hero} from './Components/Sections/Hero';
import {SectionDownload} from './Components/Sections/SectionDownload';
import {SectionTutorial} from './Components/Sections/SectionTutorial';
import {SectionPacks} from './Components/Sections/SectionPacks';
import {Modal} from './Components/Sections/Modal';
import {Bar} from './Components/Layout/Bar';
//import { BrowserRouter, Route, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import data from "./data";

function App() {

    const [ scrolled, setScrolled ] = useState();
    let content = data.ru;
    if( window.location.search === "?lang=kg" ) {
        content = data.kg;
        document.body.classList.add('is-kg');
    }

    console.log(window.location.search)

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        const handleScroll = _ => {
            if (window.pageYOffset > 60) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll)
        return _ => {
            window.removeEventListener('scroll', handleScroll)
        }
    });

    return (
        <div className={`c-site ${scrolled ? "is-sticky" : ""}`}>

            <MetaTags>
                <title>{content.title} - MegaCom</title>
                <meta name="apple-mobile-web-app-title" content={content.title} />
                <meta name="application-name" content={content.title}/>
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
                <link rel="apple-touch-icon" sizes="57x57" href={iconFavicon57} />
                <link rel="apple-touch-icon" sizes="60x60" href={iconFavicon60} />
                <link rel="apple-touch-icon" sizes="72x72" href={iconFavicon72} />
                <link rel="apple-touch-icon" sizes="76x76" href={iconFavicon76} />
                <link rel="apple-touch-icon" sizes="114x114" href={iconFavicon114} />
                <link rel="apple-touch-icon" sizes="120x120" href={iconFavicon120} />
                <link rel="apple-touch-icon" sizes="144x144" href={iconFavicon144} />
                <link rel="apple-touch-icon" sizes="152x152" href={iconFavicon152} />
                <link rel="apple-touch-icon" sizes="180x180" href={iconFavicon180} />
                <link rel="icon" type="image/png" href={iconFavicon32} sizes="32x32" />
                <link rel="icon" type="image/png" href={iconFavicon194} sizes="194x194" />
                <link rel="icon" type="image/png" href={iconFavicon96} sizes="96x96" />
                <link rel="icon" type="image/png" href={iconFavicon192} sizes="192x192" />
                <link rel="icon" type="image/png" href={iconFavicon16} sizes="16x16" />
                <meta property="og:title" content={content.title} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://m2m.megacom.kg/img/poster-ru.jpg"/>
                <meta property="og:image:secure_url" content="https://m2m.megacom.kg/img/poster-ru.jpg" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
                <meta property="og:url" content="https://m2m.megacom.kg"/>
                <meta property="og:site_name" content={content.title} />
                <meta property="og:description" content={content.title} />
                <meta name="twitter:title" content={content.title} />
                <meta name="twitter:image" content="https://m2m.megacom.kg/img/poster-ru.jpg" />
                <meta name="twitter:url" content="https://m2m.megacom.kg" />
                <meta name="twitter:card" content={content.title} />
                <link rel="image_src" href="https://m2m.megacom.kg/img/poster-ru.jpg" />
            </MetaTags>
            <Bar />
            <Header />
            <main className="c-main">
                <div className="c-main__wrapper">
                    <Hero/>
                    <SectionDownload/>
                    <SectionTutorial/>
                </div>
                <SectionPacks/>
            </main>
            <Modal />
        </div>
  );
}

/*
class Home extends React.Component {

    render()  {
        return (
            <div>
                <div className="c-main__wrapper">
                    <Hero/>
                    <SectionDownload/>
                    <SectionTutorial/>
                </div>
                <SectionPacks/>
            </div>
        );
    }
}

class Ru  extends React.Component {
    render() {
        return (
            <div>
                <div className="c-main__wrapper">
                    <Hero/>
                    <SectionDownload/>
                    <SectionTutorial/>
                </div>
                <SectionPacks/>
            </div>
        );
    }
}

class Kg  extends React.Component {
    render() {
        return (
            <div>
                <div className="c-main__wrapper">
                    <Hero/>
                    <SectionDownload/>
                    <SectionTutorial/>
                </div>
                <SectionPacks/>
            </div>
        );
    }
}
*/
export default App;
