import React, { Component } from 'react';
import data from "../../data";

export class Pack3 extends Component {

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }


    render() {
        return(
            <article className="c-pack-item c-pack-item--3" data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400">
                <header className="c-pack-item__header">
                    <div className="c-pack-item__marker">Хит</div>
                    <div className="c-pack-item__label">
                        {this.content.section_packs.label}
                    </div>
                    <div className="c-pack-item__price">
                        <div dangerouslySetInnerHTML={{ __html: this.content.section_packs["price_3"] }}></div>
                    </div>
                </header>
                <div className="c-pack-item__meta">
                    <div className="c-pack-item__period">
                        {this.content.section_packs.each_30_days}
                    </div>
                    <div className="c-pack-item__kit">
                        <div className="c-pack-item__type">
                            <strong>
                                Интернет
                            </strong>
                            <span>
                                200 МБ
                            </span>
                        </div>
                        <hr/>
                        <div className="c-pack-item__text">
                            <div dangerouslySetInnerHTML={{ __html: this.content.section_packs.unlim }}></div>
                        </div>
                        <div className="c-pack-item__type">
                            <strong>
                                {this.content.section_packs.calls}
                            </strong>
                            <span>
                                10 {this.content.section_packs.minutes}
                            </span>
                        </div>
                        <hr/>
                        <div className="c-pack-item__type">
                            <strong>
                                {this.content.section_packs.sms}
                            </strong>
                            <span>
                                100 SMS
                            </span>
                        </div>
                        <hr/>
                    </div>
                </div>
            </article>
        )
    }
}
