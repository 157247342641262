import React, { Component } from 'react';

export class Faq_item1 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                        <div>
                            1. Основные условия ТП «Умные устройства»
                        </div>
                    }
                    {this.language === '?lang=kg' &&
                        <div>
                            1. «Акылдуу түзмөктөр»  тарифтик планынын негизги шарттары
                        </div>
                    }
                </header>
                <div className="c-faq-item-content">
                {this.language !== '?lang=kg' &&
                    <div>
                    <p>Тарифный план «Умные устройства» действует на территории Кыргызской Республики.</p>
                    <p>Тарифный план «Умные устройства» доступен для индивидуальных абонентов.</p>
                    <p>Подключиться к ТП «Умные устройства» можно в любом <a
                        href="https://www.megacom.kg/pages/tsentry-prodazh-i-obsluzhivaniya?locale=ru" target="_blank">Центре
                        продаж и обслуживания</a> по Кыргызской Республике. Самостоятельный переход на ТП «Умные
                        устройства» невозможен.</p>
                    <p>Основная тарификация ТП действует при условии оплаты абонентской платы по пакету на 30, 90 или
                        360 суток для внутрисетевых вызовов и SMS. Исходящие звонки на номера мобильных и фиксированных
                        операторов КР осуществляются согласно Основной тарификации.</p>
                    <p>Порог списания абонентской платы равен: 50,00; 140,00; 490,00 для пакетов ТП «Умные устройства»
                        на 30 суток/90 суток/360 суток соответственно.</p>
                    <p>Если в момент снятия абонентской платы на вашем балансе будет сумма менее 50/140/490 сомов, то
                        все услуги исходящей мобильной связи и доступа в интернет будут отключены, за исключением
                        звонков на бесплатные номера экстренных служб. Для продолжения использования услуг мобильной
                        связи и доступа в интернет необходимо пополнить баланс на сумму, достаточную для снятия
                        абонентской платы по пакету ТП «Умные устройства».</p>
                    <p>Скорость безлимитного интернета в рамках пакетов ТП «Умные устройства» равна 128 Кбит/сек.</p>
                    <p>К внутрисетевым вызовам относятся вызовы на номера MEGA и Salam.</p>
                    <p>Стоимость абонентской платы, а также основной тарификации указана с учетом всех налогов.</p>
                    <p>Первые 3 секунды исходящих вызовов не тарифицируются в случае длительности разговора до 3-х
                        секунд, вызовы длительностью более 3-х секунд протарифицируются в полном объеме с первой
                        секунды.</p>
                    <p>При переадресации объемы минут по пакету не действуют, звонки тарифицируются согласно Основной
                        тарификации ТП.</p>
                    <p>Шаг основной тарификации вызовов вне пакета и вызовов в рамках пакетов – 1 секунда.</p>
                    <p>Шаг тарификации интернет-пакетов – 10 КБ.</p>
                    <p>В случае, если время окончания действия 30-суточного пакета ТП «Умные устройства» наступило в
                        момент активного голосового вызова внутри сети, и новые объемы не были выданы ввиду
                        недостаточности баланса для оплаты абонентской платы пакета ТП «Умные устройства», то текущий
                        голосовой вызов будет продолжен за счет средств на балансе номера без прерывания активного
                        звонка согласно основной тарификации ТП «Умные устройства». Таким образом, часть вызова внутри
                        сети будет про тарифицирована по пакету, а часть по ТП.</p>
                    <p>При обновлении пакетов (каждые 30 суток) неизрасходованные SMS, минуты и мегабайты интернета
                        сгорают.</p>
                    <p>При активации SIM-карты, по умолчанию подключаются бесплатные услуги: «Запрет коротких номеров
                        SMS», «Запрет коротких номеров IVR», «Оповещение о низком балансе».</p>
                    <p>Удобное управление ТП «Умные устройства» доступно в приложении MEGA24: 1. При использовании PIN-2
                        (на рубашке SIM-карты ТП «Умные устройства»), 2. При добавлении Основного номера (абоненты сети
                        MEGA), 3. Авторизация путем ввода SMS-кода.</p>
                    <p>При подключении ТП «Умные устройства» возможно указать до двух Основных номеров. Добавление
                        основного номера не является обязательным.</p>
                    </div>
                }
                {this.language === '?lang=kg' &&
                <div>
                    <p>«Акылдуу түзмөктөр» тарифтик планы Кыргыз Республикасынын аймагында аракетте болот.</p>
                    <p>«Акылдуу түзмөктөр»  тарифтик планы жеке абоненттер үчүн жеткиликтүү.</p>
                    <p>«Акылдуу түзмөктөр»  тарифтик планын Кыргыз Республикасынын аймагындагы
                        бардык <a href="https://www.megacom.kg/pages/tsentry-prodazh-i-obsluzhivaniya?locale=kg" target="_blank">Сатуу жана тейлөө борборлорунда</a> кошуп алууга болот.
                        "Акылдуу түзмөктөр" тарифтик планына өз алдынча өтүү мүмкүн  эмес.
                    </p>
                    <p>Тарифтик пландын негизги тарифтөөсү 30, 90 же 360 суткалык топтом боюнча түйүн ичиндеги чалуулар жана SMSтер
                        үчүн абоненттик төлөм төлөнгөн шартта  аракетте болот.  КР операторлорунун мобилдик
                        жана катталган номерлерине чыккан чалуулар Негизги тарифтөөгө ылайык ишке ашырылат.</p>
                    <p>Абоненттик төлөмдү эсептен чыгаруу чеги «Акылдуу түзмөктөр» тарифтик планынын 30 суткалык/90
                        суткалык/360 суткалык топтомдору үчүн 50,00; 140,00; 490,00 сомго барабар.</p>
                    <p>Абоненттик төлөм алынып жаткан учурда балансыңыздагы сумма 50/140/490 сомдон аз болсо,
                        шашылыш кызматтардын номерлерине  акысыз чалуулардан тышкары, чыккан мобилдик байланыш кызматтары жана
                        интернетке жетки өчүрүлөт. Мобилдик байланыш кызматтарын жана интернетти пайдаланууну улантуу үчүн балансты
                        "Акылдуу түзмөктөр" тарифтик планы боюнча абоненттик төлөмдү төлөө үчүн жетиштүү болгон суммада толуктоо зарыл.</p>
                    <p>"Акылдуу түзмөктөр" тарифтик планынын топтомдорунун алкагындагы чектөөсүз интернеттин ылдамдыгы секундасына 128 Кбит'ке барабар.</p>
                    <p>MEGA жана Salam номерлерине чалуулар түйүн ичиндеги чалууларга кирет.</p>
                    <p>Абоненттик төлөмдүн жана негизги тарифтөөнүн наркы бардык салыктарды эсепке алуу менен көрсөтүлгөн.</p>
                    <p>Сүйлөшүүнүн узактыгы 3 секундага чейин болгон учурда, чалуунун алгачкы 3 секундасы тарифтелбейт,
                        узактыгы 3 секундадан ашкан чалуулар биринчи секундадан баштап толук көлөмдө тарифтелет.</p>
                    <p>Чалууну кайра даректөөдө топтом боюнча мүнөттөрдүн көлөмү аракетте болбойт, чалуулар тарифтик пландын Негизги тарифтөөсүнө ылайык тарифтелет.</p>
                    <p>Топтомдон тышкары чалуулардын жана топтом алкагындагы чалуулардын негизги тарифтөөсүнүн кадамы 1 секунданы түзөт.</p>
                    <p>Интернет-топтомдордун тарифтөө кадамы – 10 КБ.</p>
                    <p>
                        Эгерде "Акылдуу түзмөктөр" тарифтик планынын 30 күндүк топтомунун аракет мөөнөтү түйүн ичинде активдүү
                        үн чалуу учурунда келип жетсе, жана Акылдуу түзмөктөр" тарифтик планынын топтому үчүн абоненттик
                        төлөмдү төлөө үчүн баланстын жетишсиздигинен улам жаңы көлөмдөр берилбесе, анда учурдагы үн чалуу
                        "Акылдуу түзмөктөр" ТПнын базалык тарифтөөсүнө ылайык, активдүү чалууну үзгүлтүккө учуратпай,
                        номердин балансында турган каражаттардын эсебинен улантылат.
                        Ошентип, түйүндүн ичиндеги чалуунун бир бөлүгү топтом боюнча, ал эми бир бөлүгү ТП боюнча тарифтелет.
                    </p>
                    <p>Топтомдорду жаңылоодо (ар бир 30 суткада) колдонулбай калган SMS, мүнөттөр жана интернет мегабайттары күйүп кетет.</p>
                    <p>SIM-картаны активдештирүүдө,  «Кыска SMS- номерлерге тыюу салуу», «Кыска IVR номерлерге тыюу салуу»,
                        «Жетишсиз баланс тууралуу маалымдоо» баштапкы абалында кошулат.</p>
                    <p>"Акылдуу түзмөктөр" тарифтик планын ыңгайлуу башкаруу MEGA24 тиркемесинде жана төмөндөгү учурларда жеткиликтүү:
                        1. PIN-2  кодун пайдаланууда ("Акылдуу түзмөктөр" тарифтик планынын SIM-картасынын тышкы каптамасында),
                        2. Негизги номерди кошууда (MEGA түйүнүнүн абоненттери), 3. SMS- кодду терүү жолу менен авторизациялоодон өткөндө.</p>
                    <p>"Акылдуу түзмөктөр" тарифтик планын кошууда экиден ашпаган Негизги номерди көрсөтүүгө болот. Негизги номерди кошуу милдеттүү түрдө эмес.</p>
                </div>
                }
                </div>
            </article>
        )
    }
}
