import React, { Component } from 'react';
import {Link} from 'react-scroll';
//import {withRouter} from 'react-router-dom';
import data from '../../data';

export class Bar extends Component {

    openModal = (event) => {
        document.body.classList.add('is-modal');
        this.setState({ showModal: true });

    }

    closeBar = (event) => {
        document.body.classList.remove('is-bar');
        this.setState({ showBar: false });

    }

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {

        return(
            <div className="c-bar">
                <aside className="c-bar-container">
                    <ul className="c-bar-menu">
                        <li>
                            <Link to="section-download" offset={-80} spy={true} smooth={true} onClick={this.closeBar.bind(this)}>
                                {this.content.nav.nav_connect}
                            </Link>
                        </li>
                        <li>
                            <Link to="section-packs" spy={true} smooth={true} onClick={this.closeBar.bind(this)}>
                                {this.content.nav.nav_price}
                            </Link>
                        </li>
                        <li>
                                <span onClick={this.openModal.bind(this)}>
                                    {this.content.nav.nav_faq}
                                </span>
                        </li>
                    </ul>
                </aside>
            </div>
        )
    }
}
