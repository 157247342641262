import React, { Component } from 'react';
import {Link} from 'react-scroll';
import data from '../../data';

export class Header extends Component {

    openModal = (event) => {
        document.body.classList.add('is-modal');
        this.setState({ showModal: true });
    }

    openBar = (event) => {
        document.body.classList.add('is-bar');
        this.setState({ showBar: true });
    }

    closeBar = (event) => {
        document.body.classList.remove('is-bar');
        this.setState({ showBar: false });
    }

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {

        console.log(this.language);

        return(
            <header className="c-header">
                <div className="c-layout">
                    <a href="https://www.megacom.kg/" className="c-header__logo"> </a>
                    <nav className="c-header__nav">
                        <ul className="c-header__menu">
                            <li>
                                <Link to="section-download" offset={-80} spy={true} smooth={true}>
                                    {this.content.nav.nav_connect}
                                </Link>
                            </li>
                            <li>
                                <Link to="section-packs" spy={true} smooth={true}>
                                    {this.content.nav.nav_price}
                                </Link>
                            </li>
                            <li>
                                <span onClick={this.openModal.bind(this)}>
                                    {this.content.nav.nav_faq}
                                </span>
                            </li>
                        </ul>
                        <ul className="c-header__lang">
                            <li><a
                                className={this.language !== '?lang=kg' ? 'is-active' : ''}
                                href={ process.env.PUBLIC_URL + '/index.html?lang=ru'} >Рус</a></li>
                            <li><a className={this.language === '?lang=kg' ? 'is-active' : ''}
                                   href={ process.env.PUBLIC_URL + '/index.html?lang=kg'}>Кыр</a></li>
                        </ul>
                    </nav>
                    <div className="c-header__hamburger">
                        <div className="c-header__hamburger--open" onClick={this.openBar.bind(this)}> </div>
                        <div className="c-header__hamburger--close" onClick={this.closeBar.bind(this)}> </div>
                    </div>
                </div>
            </header>
        )
    }
}
