import React, { Component } from 'react';

export class Faq_item4 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        4. Для чего нужна специальная износостойкая SIM-карта?
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        4. Тез эскирип кетпей турган (бышык) SIM-карта эмне үчүн керек?
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <p>
                            В зависимости от условий использования устройства с ТП «Умные устройства» мы предлагаем по
                            желанию абонента приобрести
                            специальную износостойкую термо-SIM-карту, предназначенную для использования устройств в
                            сложных климатических условиях, в целях предоставления стабильно качественных услуг связи:
                        </p>
                        <ul>
                            <li>При температурах от -50 °С до +105 °С;</li>
                            <li>При повышенной влажности;</li>
                            <li>При запыленности или вибрации от 5 Гц до 500 Гц;</li>
                            <li>С защитой от электростатических разрядов;</li>
                            <li>Обладает антикоррозионными свойствами.</li>
                        </ul>
                        <p>
                            Стоимость SIM-карты для устройств 100 сомов с учетом всех налогов.
                        </p>
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        <p>
                            Туруктуу сапаттуу байланыш кызматтарын көрсөтүү максатында, «Акылдуу түзмөктөр» ТП  менен
                            түзмөктү колдонуу шарттарына жараша, биз тез эскирбей турган,  түзмөктөрдү климаттык оор шарттарда
                            колдонууга арналган, атайын термо-SIM картаны сатып алууну сунуштайбыз:
                        </p>
                        <ul>
                            <li> -50 ° Cден + 105 ° Cге чейинки температурада;</li>
                            <li>Жогорку нымдуулукта;</li>
                            <li>Чаңда же 5 Гц -тен 500 Гц-ке чейинки титирөөдө;</li>
                            <li>Электростатикалык разряддан коргонуусу менен;</li>
                            <li>Коррозияга каршы касиеттерге ээ.</li>
                        </ul>
                        <p>
                            Бардык салыктарды эсепке алуу менен, шаймандар үчүн SIM-картанын наркы 100 сомду түзөт
                        </p>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
