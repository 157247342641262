import React, { Component } from 'react';
import {Faq} from '../Faq/Faq';
import data from "../../data";

export class Modal extends Component {

    hideModal = (event) => {
        document.body.classList.remove('is-modal');
        this.setState({ showModal: false });
    }

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {
        return(
            <div className="c-modal">
                <div className="c-modal-mask" onClick={this.hideModal.bind(this)}> </div>
                <div className="c-modal-main">
                    <div className="c-modal-loader"> </div>
                    <div className="c-modal-close" onClick={this.hideModal.bind(this)}> </div>

                    <section className="c-modal-container">
                        <header className="c-modal-header">
                            <div className="c-modal-title">
                                {this.content.faq_title}
                            </div>
                        </header>
                        <div className="c-modal-content c-format">
                            <Faq />
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}
