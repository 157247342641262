import React, { Component } from 'react';

export class Faq_item3 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        3. Жизненный цикл ТП «Умные устройства»
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        3. «Акылдуу түзмөктөр» тарифтик планынын урунуу циклы
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <p>
                            Для тарифного плана «Умные устройства» действуют специальные условия Жизненного цикла:
                        </p>
                        <ul>
                            <li>
                                «Активный» или «Полуактивный» режим (при условии неактивности абонента) – 361 суток;
                            </li>
                            <li>
                                Режим «Ожидание» – 20 дней.
                            </li>
                        </ul>
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        <p>
                            «Акылдуу түзмөктөр» тарифтик планы үчүн Урунуу циклынын атайын шарттары аракетте болот:
                        </p>
                        <ul>
                            <li>
                                «Активдүү» же «Жарым активдүү» режими (абонент активдүү болбогон шартта) – 361 сутка
                            </li>
                            <li>
                                «Күтүү» режими – 20 күн.
                            </li>
                        </ul>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
