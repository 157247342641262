import React, { Component } from 'react';

export class Faq_item5 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        5. Как проверить остатки по пакету?
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        5. Топтом боюнча калдыктарды кантип текшерүүгө болот?
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <p>
                            Войдите в приложение MEGA24 с помощью PIN-2 на рубашке SIM-карты с ТП «Умные устройства»;
                        </p>
                        <p>
                            или
                        </p>
                        <p>
                            Добавьте SIM-карту с ТП «Умные устройства» в приложении MEGA24 к Вашему Основному номеру
                            (обязательное условие – Основной номер должен быть номером ЗАО «Альфа Телеком») с помощью
                            PIN-2 на рубашке SIM-карты с ТП «Умные устройства»;
                        </p>
                        <p>
                            или
                        </p>
                        <p>
                            Если Вы имеете физический доступ к SIM-карте с ТП «Умные устройства», наберите USSD-команду
                            *505#
                        </p>
                    </div>
                    }

                    {this.language === '?lang=kg' &&
                    <div>
                        <p>
                            «Акылдуу түзмөктөр» ТП кошулган SIM-картанын тышкы каптамасындагы  PIN-2 кодунун жардамы менен MEGA24 тиркемесине кириңиз
                        </p>
                        <p>
                            же
                        </p>
                        <p>
                            «Акылдуу түзмөктөр» ТП кошулган SIM-картанын тышкы каптамасындагы  PIN-2 кодунун жардамы менен MEGA24 тиркемесинде
                            «Акылдуу түзмөктөр» ТП кошулган SIM-картаны  Негизги номериңизге кошуңуз
                            (милдеттүү шарт – Негизги номер «Альфа Телеком» ЖАКтын номери болуусу керек);
                        </p>
                        <p>
                            же
                        </p>
                        <p>
                            Эгерде Сизде «Акылдуу түзмөктөр» ТП кошулган SIM-картага жетки болсо, анда  *505# USSD-буйруусун териңиз.
                        </p>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
