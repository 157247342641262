import React, { Component } from 'react';
import heroImg from '../../img/hero.png';
import heroImg2x from '../../img/hero@2x.png';
import heroBg from '../../img/hero-bg.png';
import data from "../../data";

export class Hero extends Component {

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {
        return(
            <section className="c-section" id="section-hero">
                <div className="c-section__particulars"> </div>
                <div className="c-section__bg">
                    <img src={heroBg} alt=""/>
                </div>
                <div className="c-layout">
                    <div className="c-hero">
                        <div className="c-hero__meta">
                            <div className="c-hero__title" data-aos="fade-up" data-aos-duration="300" data-aos-delay="100">
                                {this.content.title}
                            </div>
                            <div className="c-hero__text" data-aos="fade-up" data-aos-duration="300" data-aos-delay="200">
                                {this.content.description.main}
                            </div>
                            <ul className="c-hero__facts">
                                <li data-aos="zoom-out" data-aos-duration="300" data-aos-delay="300">
                                    <div dangerouslySetInnerHTML={{ __html: this.content.description.sim }}></div>
                                </li>
                                <li data-aos="zoom-out" data-aos-duration="300" data-aos-delay="400">
                                    <div className="c-hero__price">
                                        <div dangerouslySetInnerHTML={{ __html: this.content.description.price }}></div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div className="c-hero__media">
                            <img src={heroImg} srcSet={`${heroImg2x} 2x`} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}
