import React, { Component } from 'react';
import phoneImg from '../../img/phone_mega24.png';
import phoneImg2x from '../../img/phone_mega24@2x.png';
import appAppstore from '../../img/app-appsore.svg';
import appGoogleplay from '../../img/app-googleplay.svg';
import data from "../../data";

export class SectionDownload extends Component {

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }

    render() {
        return(
            <section className="c-section" id="section-download">
                <div className="c-layout">
                    <div className="c-section__title" data-aos="fade-up" data-aos-duration="300" data-aos-delay="100">
                        {this.content.section_download.title}
                    </div>
                    <div className="c-section__text" data-aos="fade-up" data-aos-duration="300" data-aos-delay="200">
                        <div dangerouslySetInnerHTML={{ __html: this.content.section_download.subtitle }}></div>
                    </div>
                    <div className="c-section__container">
                        <div className="c-section__media" data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
                            <figure className="c-figure--phone-mega24">
                                <img src={phoneImg} srcSet={`${phoneImg2x} 2x`} alt=""/>
                            </figure>
                        </div>
                        <div className="c-section__meta">
                            <div className="c-apps">
                                <div className="c-apps__title" data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
                                    <div dangerouslySetInnerHTML={{ __html: this.content.section_download.text }}></div>
                                </div>
                                <div className="c-apps__list">
                                    <a href="https://apps.apple.com/us/app/mega24/id1501289212" className="c-apps__appstore" target="_blank" data-aos="zoom-out" data-aos-duration="300" data-aos-delay="400">
                                        <img src={appAppstore} alt=""/>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=kg.megacom.fun&hl=ru" className="c-apps__googleplay" target="_blank" data-aos="zoom-out" data-aos-duration="300" data-aos-delay="500">
                                        <img src={appGoogleplay} alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
