import React, { Component } from 'react';
import {Pack1} from './../Packs/Pack1';
import {Pack2} from './../Packs/Pack2';
import {Pack3} from './../Packs/Pack3';
import imgLine from "../../img/footer-bg.svg";
import data from "../../data";

export class SectionPacks extends Component {

    constructor(props) {
        super(props);
        this.language = window.location.search;
        if( this.language === "?lang=kg" ) {
            this.content = data.kg
        } else {
            this.content = data.ru
        }
    }


    render() {
        return(
            <section className="c-section" id="section-packs">
                <div className="c-section__line">
                    <img src={imgLine} alt=""/>
                </div>
                <div className="c-layout">
                    <div className="c-section__title" data-aos="fade-up" data-aos-duration="300" data-aos-delay="100">
                        <div dangerouslySetInnerHTML={{ __html: this.content.section_packs.title }}></div>
                    </div>
                    <div className="c-packs-list">
                        <Pack1/>
                        <Pack2/>
                        <Pack3/>
                    </div>
                    <div className="c-section__subtext" data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
                        {this.content.section_packs.description}
                    </div>
                </div>
            </section>
        )
    }
}
