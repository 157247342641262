import React, { Component } from 'react';

export class Faq_item6 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        6. Как увеличить срок действия пакета?
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        6. Топтомдун колдонуу мөөнөтүн кантип узартууга болот?
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <p>
                            Войдите в приложение MEGA24 с помощью PIN-2 на рубашке SIM-карты с ТП «Умные устройства»;
                        </p>
                        <p>
                            или
                        </p>
                        <p>
                            Добавьте SIM-карту с ТП «Умные устройства» в приложении MEGA24 к Вашему Основному номеру
                            (обязательное условие – Основной номер должен быть номером ЗАО «Альфа Телеком») с помощью
                            PIN-2 на рубашке SIM-карты с ТП «Умные устройства»; и выберите нужный Вам пакет: на 30
                            суток, на 90 суток или на 360 суток и оплатите абонентскую плату по пакету. Остатки по
                            предыдущему пакета не суммируются и не переносятся.
                        </p>
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        <p>
                            «Акылдуу түзмөктөр» ТП кошулган SIM-картанын тышкы каптамасындагы  PIN-2 кодунун жардамы менен MEGA24 тиркемесине кириңиз
                        </p>
                        <p>
                            же
                        </p>
                        <p>
                            «Акылдуу түзмөктөр» ТП кошулган SIM-картанын тышкы каптамасындагы  PIN-2 кодунун жардамы менен MEGA24
                            тиркемесинде «Акылдуу түзмөктөр» ТП кошулган SIM-картаны  Негизги номериңизге кошуңуз
                            (милдеттүү шарт – Негизги номер «Альфа Телеком» ЖАКтын номери болуусу керек);
                            өзүңүзгө керектүү 30 суткалык, 90 суткалык же 360 суткалык топтомду тандаңыз жана топтом боюнча абоненттик төлөмдү төлөңүз.
                            Буга чейинки топтомдун калдыктары суммаланбайт жана которулбайт.
                        </p>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
