import React, { Component } from 'react';

export class Faq_item2 extends Component {

    state = {
        isShow: false
    };

    handleToggle = () => {
        const { isShow } = this.state;
        this.setState({ isShow: !isShow });
    };

    constructor(props) {
        super(props);
        this.language = window.location.search;
    }

    render() {
        return(
            <article
                data-id={this.index}
                className={`c-faq-item ${this.state.isShow ? "is-open" : ""}`}>
                <header
                    onClick={this.handleToggle}
                    className={`c-faq-item-title ${this.state.isShow ? "is-active" : ""}`}
                >
                    {this.language !== '?lang=kg' &&
                    <div>
                        2. Основная тарификация ТП «Умные устройства»
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        2. «Акылдуу түзмөктөр» тарифтик планынын негизги тарифтөөсү
                    </div>
                    }
                </header>
                <div className="c-faq-item-content">
                    {this.language !== '?lang=kg' &&
                    <div>
                        <div className="c-table-wrapper">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        Исходящие голосовые и видео вызовы на номера ЗАО «Альфа Телеком» и на номера бренда «Salam», за 1 минуту
                                    </td>
                                    <td>
                                        1,00 сом.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Исходящие голосовые и видео вызовы на номера мобильных операторов КР, за 1
                                        минуту
                                    </td>
                                    <td>
                                        2,80 сом.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Исходящие на фиксированные номера операторов КР, за 1 минуту
                                    </td>
                                    <td>
                                        5,95 сом.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Исходящие вызовы на номера международных операторов, за 1 минуту
                                    </td>
                                    <td>
                                        согласно тарификации услуги <a
                                        href="https://www.megacom.kg/services/el-aralyk-baylanysh?locale=ru"
                                        target="_blank">«Международный доступ»</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Исходящие SMS на номера мобильных операторов КР (в том числе внутри сети MEGA), за 1 шт.
                                    </td>
                                    <td>
                                        1,00 сом.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Все входящие вызовы для номеров с непрямой нумерацией, входящие международные
                                        звонки и с мобильных номеров операторов КР (для номеров с прямой нумерацией), за
                                        1 минуту<br/>
                                        Важно: Для <strong>прямых номеров</strong> входящие вызовы тарифицируются
                                        согласно ТО «<a href="https://www.megacom.kg/pages/pryamaya-numeraciya?locale=ru" target="_blank">Прямая нумерация</a>».
                                    </td>
                                    <td>
                                        0,00 сом.
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>
                            <strong>Важно:</strong> Исходящие звонки на фиксированные номера 996312926хxx -996312927ххх
                            Государственного Предприятия «НК «Кыргыз Темир Жолу»», 99631289xxxx ОсОО «Газпром Кыргызстан»,
                            99631296xxx - 996312964ххх ОсОО «Сатком», 99632229хxxx - 99632229xххх ОсОО «Абател» тарифицируются по 8,35 сомов за 1 минуту разговора.
                        </p>
                        <p><em>Цены указаны с учетом НДС и НсП.</em></p>
                    </div>
                    }
                    {this.language === '?lang=kg' &&
                    <div>
                        <div className="c-table-wrapper">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        «Альфа Телеком» ЖАКтын номерлерине,
                                        «Salam» брендинин номерлерине чыккан үн жана видео чалуулар, 1 мүнөтү үчүн
                                    </td>
                                    <td>
                                        1,00 сом
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        КРдин мобилдик операторлорунун номерлерине үн жана видео чалуулар, 1 мүнөтү үчүн
                                    </td>
                                    <td>
                                        2,80 сом
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        КРдин катталган операторлорунун номерлерине чыккан чалуулар, 1 мүнөтү үчүн
                                    </td>
                                    <td>
                                        5,95 сом
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Эл аралык операторлорунун номерлерине чыккан чалуулар, 1 мүнөтү үчүн
                                    </td>
                                    <td>
                                        <a href="https://www.megacom.kg/services/el-aralyk-baylanysh?locale=ru"
                                        target="_blank">«Эл аралык байланыш»</a> кызматынын тарифтөөсүнө ылайык
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        КРдин мобилдик операторлорунун номерлерине чыккан  SMSтер (анын ичинде MEGA түйүнүнүн ичинде), 1 SMS үчүн
                                    </td>
                                    <td>
                                        1,00 сом
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Түз номерлөөсү менен номерлер үчүн бардык кирген чалуулар, кирген эл аралык чалуулар жана КРдин
                                        операторлорунун мобилдик операторлорунун номерлеринен кирген чалуулар (түз номерлөөсү менен номерлер үчүн),
                                        1 мүнөтү үчүн<br/>
                                        Маанилүү: Түз номерлер үчүн кирген чалуулар «<a href="https://www.megacom.kg/pages/pryamaya-numeraciya?locale=kg" target="_blank">Түз номерлөө</a>» ТОсуна ылайык тарифтелет.
                                    </td>
                                    <td>
                                        0,00 сом
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>
                            <strong>Маанилүү:</strong> «НК «Кыргыз Темир Жолу»» мамлекеттик ишканасынын: 996312926хxx- 996312927ххх,
                            «Газпром Кыргызстан» ЖЧКнын: 99631289xxxx, «Сатком» ЖЧКнын: 99631296xxx - 996312964ххх, «Абател» ЖЧКнын: 99632229хxxx - 99632229xххх
                            катталган номерлерине чыккан чалуулар мүнөтүнө 8,35 сомдон тарифтелет.
                        </p>
                        <p><em>Баалар КНСти жана ССти эсепке алуу менен көрсөтүлгөн.</em></p>
                    </div>
                    }
                </div>
            </article>
        )
    }
}
